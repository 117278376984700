import React, { forwardRef, useState } from 'react';

const MaskedPinInput = forwardRef(({ value, onChange, onBackspace }, ref) => {
  const [secureValue, setSecureValue] = useState(value);

  const handleChange = (e) => {
    const text = e.target.value;
    setSecureValue(text ? '•' : '');
    onChange(text);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Backspace' && !secureValue) {
      onBackspace();
    }
  };

  return (
    <input
      ref={ref}
      type="text"
      value={secureValue}
      onChange={handleChange}
      onKeyDown={handleKeyDown}
      maxLength="1"
      style={{ width: '50px', height: '50px', textAlign: 'center', fontSize: '24px', margin: '5px' }}
    />
  );
});

export default MaskedPinInput;