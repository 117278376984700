import React from 'react';
import '../Chtas-Style/dotsMenuModal.css';
import searchIcon from '../Assets/Se.png';
import clearHistoryIcon from '../Assets/clear.png';
import closeIcon from '../Assets/cancel.png';

const DotsMenuModal = ({ show, onClose, onDelete }) => {
  if (!show) {
    return null;
  }

  return (
    <div className="dots-menu-modal-overlay" onClick={onClose}>
      <div className="dots-menu-modal-content" onClick={(e) => e.stopPropagation()}>
        <div className="dots-menu-options">
          <div className="dots-menu-option" onClick={() => console.log('Search')}>
            <img src={searchIcon} alt="Search" />
            <span>Search</span>
          </div>
          <div className="dots-menu-option" onClick={onDelete}>
            <img src={clearHistoryIcon} alt="Clear History" />
            <span>Clear History</span>
          </div>
          <div className="dots-menu-option" onClick={onClose}>
            <img src={closeIcon} alt="Close" />
            <span>Close</span>
          </div>
        </div>
      </div>
    </div>
  );
};
export default DotsMenuModal;
