import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { doc, getDoc, setDoc, getFirestore } from 'firebase/firestore';
import app from './firebaseConfig';
import MaskedPinInput from './SetPinInput';
import '../Styles/PinSetup.css';
import Logo from '../Assets/Logo.png';
const PinSetupScreen = () => {

  const navigate = useNavigate();

  const db = getFirestore(app);
  const [chatPin, setChatPin] = useState(['', '', '', '']);
  const [appPin, setAppPin] = useState(['', '', '', '']);
  const chatPinInputs = useRef([]);
  const appPinInputs = useRef([]);
  const [userId, setUserId] = useState(null);

  useEffect(() => {
    const getUserId = async () => {
      try {
        const user = localStorage.getItem('user');
        if (user) {
          const { userID } = JSON.parse(user);
          console.log('Retrieved user ID:', userID);
          setUserId(userID);
        }
      } catch (error) {
        console.error('Error retrieving user ID:', error);
      }
    };

    getUserId();
  }, []);

  const handleChatPinChange = (index, value) => {
    const newChatPin = [...chatPin];
    newChatPin[index] = value;
    setChatPin(newChatPin);
    if (value !== '' && index < chatPin.length - 1) {
      chatPinInputs.current[index + 1].focus();
    }
  };

  const handleAppPinChange = (index, value) => {
    const newAppPin = [...appPin];
    newAppPin[index] = value;
    setAppPin(newAppPin);
    if (value !== '' && index < appPin.length - 1) {
      appPinInputs.current[index + 1].focus();
    }
  };

  const handleChatPinBackspace = (index, value) => {
    if (value === '' && index > 0) {
      chatPinInputs.current[index - 1].focus();
    }
  };

  const handleAppPinBackspace = (index, value) => {
    if (value === '' && index > 0) {
      appPinInputs.current[index - 1].focus();
    }
  };

  const savePins = async () => {
    try {
      if (!userId) {
        throw new Error('User ID not found. Please login again.');
      }

      if (chatPin.some(pin => pin === '') || appPin.some(pin => pin === '')) {
        throw new Error('PINs not generated. Please try again.');
      }
      if (chatPin.join('') === appPin.join('')) {
        throw new Error('Chat PIN and App PIN cannot be the same.');
      }
      await setDoc(
        doc(db, 'users', userId),
        {
          chatPin: chatPin.join(''),
          appPin: appPin.join(''),
        },
        { merge: true }
      );
      navigate('/enter-pin');
      alert('Pins saved successfully!');
    } catch (error) {
      console.error('Error saving pins:', error);
      alert('Error', error.message);
    }
  };

  return (
    <div className="containersetPin">
      {/* <img src="../Images/Background.jpeg" className="image" alt="background" /> */}
      <div className="overlay1">
        <img src={Logo} className="logosetPin" alt="logo" />
        <h1 className="heading">CIT'S</h1>
        <div className="formInput">
          <h2 className="title">Set your 4-digit Chat PIN</h2>
          <div className="inputContainer">
            {chatPin.map((digit, index) => (
              <MaskedPinInput
                key={index}
                ref={ref => (chatPinInputs.current[index] = ref)}
                value={digit}
                onChange={value => handleChatPinChange(index, value)}
                onBackspace={() => handleChatPinBackspace(index, digit)}
              />
            ))}
          </div>

          <h2 className="title">Set your 4-digit App PIN</h2>
          <div className="inputContainer">
            {appPin.map((digit, index) => (
              <MaskedPinInput
                key={index}
                ref={ref => (appPinInputs.current[index] = ref)}
                value={digit}
                onChange={value => handleAppPinChange(index, value)}
                onBackspace={() => handleAppPinBackspace(index, digit)}
              />
            ))}
          </div>
          <button className="enterButton" onClick={savePins}>
            <span className="buttonText">Verify Pin</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default PinSetupScreen;
