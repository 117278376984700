import React, { useRef, useState } from 'react';
import '../Chtas-Style/attachmentModal.css';
import galleryIcon from '../Assets/gallery.png';
import cameraIcon from '../Assets/camera.jpg';
import locationIcon from '../Assets/location.png';
import gifIcon from '../Assets/gif.png';
import videoIcon from '../Assets/video.png';
import documentIcon from '../Assets/document.jpeg';
import pollIcon from '../Assets/poll.png';
import captureIcon from '../Assets/capture.png';
import switchCameraIcon from '../Assets/switchcamera.png';
import closeCameraIcon from '../Assets/cancel.png';
import { getStorage, ref as firebaseRef, uploadBytes, getDownloadURL } from 'firebase/storage';
import { getFirestore, addDoc, collection } from 'firebase/firestore';
import Webcam from 'react-webcam';
import Modal from 'react-modal';

const AttachmentModal = ({ show, onClose, userID, userId, userName, handleSend }) => {
  const fileInputRef = useRef(null);
  const webcamRef = useRef(null);
  const [cameraOpen, setCameraOpen] = useState(false);
  const [facingMode, setFacingMode] = useState('user');
  const storage = getStorage();
  const db = getFirestore();
  const [pollQuestion, setPollQuestion] = useState('');
  const [pollOptions, setPollOptions] = useState(['', '', '', '']);
  const [showPollForm, setShowPollForm] = useState(false);

  const handleFileChange = async (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      const fileType = selectedFile.type.split('/')[0];
      const fileRef = firebaseRef(storage, `chat_${fileType}/${userID}-${Date.now()}`);
      await uploadBytes(fileRef, selectedFile);
      const downloadURL = await getDownloadURL(fileRef);

      const newMsg = {
        [fileType]: downloadURL,
        user: { name: userName, senderId: userID },
        senderId: userID,
        receiverId: userId,
        createdAt: new Date(),
      };

      await addDoc(collection(db, 'messages'), newMsg);
      onClose();
    } else {
      console.log('No file selected');
    }
  };

  const handleCapture = async () => {
    setCameraOpen(false);
    const imageSrc = webcamRef.current.getScreenshot();
    if (imageSrc) {
      const response = await fetch(imageSrc);
      const blob = await response.blob();

      const imageRef = firebaseRef(storage, `chat_image/${userID}-${Date.now()}`);
      await uploadBytes(imageRef, blob);
      const downloadURL = await getDownloadURL(imageRef);

      const newMsg = {
        image: downloadURL,
        user: { name: userName, senderId: userID },
        senderId: userID,
        receiverId: userId,
        createdAt: new Date(),
      };

      await addDoc(collection(db, 'messages'), newMsg);
      onClose();
    } else {
      console.log('No image captured');
    }
  };

  const handleGalleryClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.accept = 'image/*';
      fileInputRef.current.click();
    }
  };

  const handleVideoClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.accept = 'video/*';
      fileInputRef.current.click();
    }
  };

  const handleCameraClick = () => {
    setCameraOpen(true);
    onClose();
  };

  const handleCloseCamera = () => {
    setCameraOpen(false);
  };

  const handleSwitchCamera = () => {
    setFacingMode((prevMode) => (prevMode === 'user' ? 'environment' : 'user'));
  };

  const handleSendLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(async (position) => {
        const { latitude, longitude } = position.coords;
        const newMsg = {
          location: { latitude, longitude },
          user: { name: userName, senderId: userID },
          senderId: userID,
          receiverId: userId,
          createdAt: new Date(),
        };

        await addDoc(collection(db, 'messages'), newMsg);
        onClose();
      }, (error) => {
        console.error('Error getting location:', error);
      });
    } else {
      console.error('Geolocation is not supported by this browser.');
    }
  };

  const handleSendDocument = async (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      const fileRef = firebaseRef(storage, `uploads/${userID}-${Date.now()}-${selectedFile.name}`);
      await uploadBytes(fileRef, selectedFile);
      const downloadURL = await getDownloadURL(fileRef);

      const newMsg = {
        file: downloadURL,
        fileName: selectedFile.name,
        user: { name: userName, senderId: userID },
        senderId: userID,
        receiverId: userId,
        createdAt: new Date(),
      };

      await addDoc(collection(db, 'messages'), newMsg);
      onClose();
    } else {
      console.log('No file selected');
    }
  };

  const sendPoll = () => {
    if (pollQuestion && pollOptions.filter(opt => opt).length >= 2) {
      const messageId = Date.now().toString();
      const message = {
        _id: messageId,
        text: pollQuestion,
        createdAt: new Date(),
        user: { _id: userID },
        poll: pollOptions.filter(opt => opt).map(option => ({
          option,
          votes: 0,
          voters: []  // This will store the IDs of users who have voted for this option
        })),
        senderId: userID,
        receiverId: userId,
      };
      handleSend([message]);
      setPollQuestion('');
      setPollOptions(['', '', '', '']);
      setShowPollForm(false);
    } else {
      alert('Please enter a question and at least two options.');
    }
  };

  const renderPollModal = () => (
    <Modal
      isOpen={showPollForm}
      onRequestClose={() => setShowPollForm(false)}
      style={customStyles}
    >
      <div className="pollModalContent">
        <input
          type="text"
          className="pollQuestionInput"
          placeholder="Enter poll question"
          value={pollQuestion}
          onChange={(e) => setPollQuestion(e.target.value)}
        />
        {pollOptions.map((option, index) => (
          <input
            key={index}
            type="text"
            className="pollOptionInput"
            placeholder={`Option ${index + 1}`}
            value={option}
            onChange={(e) => {
              const newOptions = [...pollOptions];
              newOptions[index] = e.target.value;
              setPollOptions(newOptions);
            }}
          />
        ))}
        <button onClick={sendPoll} className="sendPollButton">
          Send Poll
        </button>
      </div>
    </Modal>
  );

  return (
    <>
      {show && !cameraOpen && (
        <div className="attachment-modal" onClick={onClose}>
          <div className="attachment-modal-content" onClick={(e) => e.stopPropagation()}>
            <div className="attachment-options">
              <div className="attachment-option" onClick={handleGalleryClick}>
                <img src={galleryIcon} alt="Gallery" />
                <span>Gallery</span>
              </div>
              <div className="attachment-option" onClick={handleCameraClick}>
                <img src={cameraIcon} alt="Camera" />
                <span>Camera</span>
              </div>
              <div className="attachment-option" onClick={handleSendLocation}>
                <img src={locationIcon} alt="Location" />
                <span>Location</span>
              </div>
              <div className="attachment-option" onClick={() => console.log('Gif')}>
                <img src={gifIcon} alt="Gif" />
                <span>Gif</span>
              </div>
              <div className="attachment-option" onClick={handleVideoClick}>
                <img src={videoIcon} alt="Video" />
                <span>Video</span>
              </div>
              <div className="attachment-option" onClick={() => document.getElementById('documentInput').click()}>
                <input
                  type="file"
                  accept=".pdf,.doc,.docx,.txt"
                  style={{ display: 'none' }}
                  id="documentInput"
                  onChange={handleSendDocument}
                />
                <img src={documentIcon} alt="Document" />
                <span>Document</span>
              </div>
              <div className="attachment-option" onClick={() => {
                onClose();
                setShowPollForm(true);
              }}>
                <img src={pollIcon} alt="Poll" />
                <span>Poll</span>
              </div>
            </div>
            <input
              type="file"
              style={{ display: 'none' }}
              ref={fileInputRef}
              onChange={handleFileChange}
            />
          </div>
        </div>
      )}
      {renderPollModal()}
      {cameraOpen && (
        <div className="camera-modal">
          <Webcam
            audio={false}
            ref={webcamRef}
            screenshotFormat="image/jpeg"
            videoConstraints={{ facingMode }}
            className="webcam"
          />
          <div className="camera-controls">
            <img src={captureIcon} alt="Capture" onClick={handleCapture} className='Capture'/>
            <img src={switchCameraIcon} alt="Switch Camera" onClick={handleSwitchCamera} className='Capture'/>
            <img src={closeCameraIcon} alt="Close Camera" onClick={handleCloseCamera} className='Capture'/>
          </div>
        </div>
      )}
    </>
  );
};

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

export default AttachmentModal;
