import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { getFirestore, collection, setDoc, getDocs, query, where, deleteDoc, doc, onSnapshot, updateDoc, getDoc } from 'firebase/firestore';
import Modal from 'react-modal';
import { getAuth } from 'firebase/auth';
import '../Chtas-Style/Chat-Home.css';
import user from '../Assets/7309681.jpg';
import NewChats from '../Assets/newChat.png';

const Chatspage = () => {
  const [recentChatUsers, setRecentChatUsers] = useState([]);
  const [userID, setUserID] = useState(null);
  const [isModalVisible, setModalVisible] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [animatedValues, setAnimatedValues] = useState({});
  const db = getFirestore();
  const appState = useRef('active');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserID = () => {
      try {
        const storedUser = localStorage.getItem('user');
        if (storedUser) {
          const { userID } = JSON.parse(storedUser);
          console.log('Retrieved user ID:', userID);
          setUserID(userID);
        } else {
          console.log('User ID not found in localStorage');
        }
      } catch (error) {
        console.error('Error fetching user ID from localStorage:', error);
      }
    };
    fetchUserID();
  }, []);

  useEffect(() => {
    if (userID) {
      loadRecentChatUsers(userID);
    }
  }, [userID]);

  const updateLastOnlineTime = async (userId, currentTime) => {
    try {
      const ISTOptions = {
        timeZone: 'Asia/Kolkata',
        hour12: true,
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric'
      };

      const istTime = new Date(currentTime).toLocaleString('en-US', ISTOptions);
      const userRef = doc(db, 'users', userId);
      await setDoc(userRef, { lastOnline: istTime }, { merge: true });
    } catch (error) {
      console.error('Error updating last online time:', error);
    }
  };

  const updateOnlineStatus = async (isOnline) => {
    try {
      const auth = getAuth();
      const user = auth.currentUser;
      const userID = user ? user.uid : null;
  
      if (userID) {
        const userRef = doc(db, 'users', userID);
        console.log('Updating online status for user:', userID, 'Status:', isOnline);
        await setDoc(userRef, { online: isOnline }, { merge: true });
        console.log('Online status updated successfully.');
      } else {
        console.log('No user is currently logged in.');
      }
    } catch (error) {
      console.error('Error updating online status:', error);
    }
  };

  useEffect(() => {
    const handleAppStateChange = async (nextAppState) => {
      if (appState.current.match(/inactive|background/) && nextAppState === 'active') {
        updateOnlineStatus(true);
        console.log('App has come to the foreground!');
      } else if (nextAppState === 'background') {
        updateOnlineStatus(false);
        console.log('App has gone to the background!');
        const storedUser = localStorage.getItem('user');
        if (storedUser) {
          const { userID } = JSON.parse(storedUser);
          const currentTime = new Date().toISOString();
          await updateLastOnlineTime(userID, currentTime);
        }
      }
      appState.current = nextAppState;
    };

    window.addEventListener('visibilitychange', () => {
      if (document.hidden) {
        handleAppStateChange('background');
      } else {
        handleAppStateChange('active');
      }
    });

    return () => {
      window.removeEventListener('visibilitychange', handleAppStateChange);
    };
  }, [updateLastOnlineTime]);

  const loadRecentChatUsers = async (userId) => {
    try {
      const recentChatsRef = collection(db, 'recentChats');
      const q = query(recentChatsRef, where('userId', '==', userId));
      const querySnapshot = await getDocs(q);
      const users = await Promise.all(querySnapshot.docs.map(async docSnapshot => {
        const userData = docSnapshot.data();
        const userRef = doc(db, 'users', userData.chatUserId);
        const userDoc = await getDoc(userRef);
        return { id: docSnapshot.id, ...userData, profilePic: userDoc.exists() ? userDoc.data().profilePic : null };
      }));
      setRecentChatUsers(users);

      const animatedVals = {};
      users.forEach(user => {
        animatedVals[user.id] = 0; // Initialize with 0 instead of Animated.Value
      });
      setAnimatedValues(animatedVals);

      // Listen for new messages and update unread count
      users.forEach(user => {
        const messagesRef = collection(db, 'messages');
        const messagesQuery = query(messagesRef, where('receiverId', '==', userId), where('senderId', '==', user.chatUserId), where('read', '==', false));
        onSnapshot(messagesQuery, snapshot => {
          const unreadCount = snapshot.docs.length;
          setRecentChatUsers(prevUsers => prevUsers.map(u => u.id === user.id ? { ...u, unreadCount } : u));
        });
      });
    } catch (error) {
      console.error('Error loading recent chat users:', error);
    }
  };

  const handleHideChat = async () => {
    if (selectedUser) {
      try {
        const recentChatsRef = collection(db, 'recentChats');
        const q = query(recentChatsRef, where('userId', '==', userID), where('chatUserId', '==', selectedUser.chatUserId));
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach(async (docSnapshot) => {
          await deleteDoc(docSnapshot.ref);
        });
        loadRecentChatUsers(userID);
        setModalVisible(false);
      } catch (error) {
        console.error('Error hiding chat:', error);
      }
    }
  };

  const NoChatsComponent = () => (
    <div className="noChatsContainer">
      <p className="ChatsText">Start Chatting</p>
      <p className="noChatsText">Message Secretly with your Loved Ones. No Matter What device they used.</p>
    </div>
  );

  const handleSwipeLeft = (id) => {
    if (animatedValues[id] !== undefined) {
      setAnimatedValues(prevValues => ({
        ...prevValues,
        [id]: -100,
      }));
      setTimeout(() => {
        setSelectedUser(recentChatUsers.find(user => user.id === id));
        setModalVisible(true);
        setAnimatedValues(prevValues => ({
          ...prevValues,
          [id]: 0,
        }));
      }, 300);
    }
  };

  const renderItem = (item) => (
    <div key={item.id} style={{ transform: `translateX(${animatedValues[item.id] || 0}px)` }}>
      <div className="recentChatUserItem">
        <div
          className="chatItemContainer"
          onClick={() => {
            const messagesRef = collection(db, 'messages');
            const messagesQuery = query(messagesRef, where('receiverId', '==', userID), where('senderId', '==', item.chatUserId), where('read', '==', false));
            getDocs(messagesQuery).then(querySnapshot => {
              querySnapshot.forEach(doc => {
                updateDoc(doc.ref, { read: true });
              });
            });

            navigate('/chat', { state: { userId: item.chatUserId, userMail: item.email, username: item.username } });
          }}
        >
          <div className="userDetailsContainer">
            <img src={item.profilePic ? item.profilePic : user} className="userImage" alt="user" />
            <p className="text">{item.username.charAt(0).toUpperCase() + item.username.slice(1)}</p>
          </div>
          <p className="lastMessageText">Last Message</p>
          {item.unreadCount > 0 && (
            <div className="unreadCountContainer">
              <p className="unreadCountText">{item.unreadCount}</p>
            </div>
          )}
        </div>
        <p className="messageNowText">Message Now</p>
      </div>
    </div>
  );

  return (
    <div className="containerchatshome">
      <div className="headerContainer">
        <p className="headerText">Chats</p>
      </div>
      <div className="flatList">
        {recentChatUsers.length > 0 ? recentChatUsers.map(user => renderItem(user)) : <NoChatsComponent />}
      </div>
      <Modal
        isOpen={isModalVisible}
        onRequestClose={() => setModalVisible(false)}
        contentLabel="Hide Chat"
        className="modal"
        overlayClassName="overlay"
      >
        <div className="modalContent">
          <p className="modalTitle">Hide Chat</p>
          <p className="modalText">Do you want to hide this chat?</p>
          <div className="modalButtons">
            <button className="modalButton" onClick={handleHideChat}>
              <p className="modalButtonText">Yes</p>
            </button>
            <button className="modalButton" onClick={() => setModalVisible(false)}>
              <p className="modalButtonText">No</p>
            </button>
          </div>
        </div>
      </Modal>
      <button className="newChatButton" onClick={() => navigate('/user-search')}>
        <img src={NewChats} className="chatIcon" alt="new chat" />
      </button>
    </div>
  );
};

export default Chatspage;
