import React, { useState, useEffect, useRef } from 'react';
import { getFirestore, collection, addDoc, query, where, onSnapshot, orderBy, getDoc, doc, updateDoc, deleteDoc, setDoc, writeBatch, getDocs } from 'firebase/firestore';
import { useLocation, useNavigate } from 'react-router-dom';
import '../Chtas-Style/chat.css';
import arrowLeft from '../Assets/left-chevron.png';
import Video from '../Assets/video.png';
import Voice from '../Assets/voice.png';
import Dots from '../Assets/dots.png';
import attachIcon from '../Assets/paperclip.png';
import emojiIcon from '../Assets/emoji.png';
import cameraIcon from '../Assets/camera1.png';
import Picker from 'emoji-picker-react';
import { useSwipeable } from 'react-swipeable';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import replyIcon from '../Assets/reply.png';
import copyIcon from '../Assets/copy.png';
import forwardIcon from '../Assets/forward.png';
import deleteIcon from '../Assets/deleteIcon.png';
import AttachmentModal from './AttachmentModal';
import DotsMenuModal from './DotsMenuModal';
import Modal from 'react-modal';
import User from "../Assets/7309681.jpg";
import { ZIM } from "zego-zim-web";
import { ZegoUIKitPrebuilt } from '@zegocloud/zego-uikit-prebuilt';
import ChatTheam from '../Assets/Chat_background.png';
import { getDownloadURL, uploadBytesResumable, ref as firebaseRef } from 'firebase/storage';
import { getAuth } from 'firebase/auth';
import { storage } from "../Components/firebaseConfig";
import DeliveredIcon from '../Assets/deliverd.svg';
import Check from '../Assets/done-all.svg';
const ChatPage = () => {
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [replyingTo, setReplyingTo] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [modalPosition, setModalPosition] = useState({ top: 0, left: 0 });
  const [selectedMessage, setSelectedMessage] = useState(null);
  const location = useLocation();
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const { state } = location;
  const db = getFirestore();
  const messagesEndRef = useRef(null);
  const [showAttachmentModal, setShowAttachmentModal] = useState(false);
  const [showDotsMenuModal, setShowDotsMenuModal] = useState(false);
  const [userID, setUserID] = useState(null);
  const userId = state?.userId;
  const [userName, setUserName] = useState('');
  const [showMoreReactions, setShowMoreReactions] = useState(false);
  const username = state?.username;
  const [opponentProfileImage, setOpponentProfileImage] = useState('');
  const [opponentOnlineStatus, setOpponentOnlineStatus] = useState('offline');
  const [Themes, setTheams] = useState();
  const navigate = useNavigate();
  const zp = useRef(null);
  const [lastOnline, setLastOnline] = useState(null);
  const appState = useRef(document.visibilityState);
  const [isTyping, setIsTyping] = useState(false);
  console.log('typing:', isTyping);
  useEffect(() => {
    const fetchUserID = async () => {
      try {
        const storedUser = localStorage.getItem('user');
        const { userID } = JSON.parse(storedUser);
        console.log('Retrieved User ID:', userID);
        if (userID) {
          setUserID(userID);
        } else {
          console.log('User ID not found in localStorage');
        }
      } catch (error) {
        console.error('Error fetching user ID from localStorage:', error);
      }
    };
    fetchUserID();
  }, []);
  useEffect(() => {
    const fetchUsername = async () => {
      try {
        if (userID) {
          const userRef = doc(db, 'users', userID);
          const userSnapshot = await getDoc(userRef);
          if (userSnapshot.exists()) {
            const userData = userSnapshot.data();
            console.log(userData)
            if (userData && userData.name) {
              setUserName(userData.name);
              setTheams(userData.ThemesChat || ChatTheam);
              clearInterval(fetchInterval);
            } else {
              console.log('User name not found in user data');
            }
          } else {
            console.log('User not found');
          }
        }
      } catch (error) {
        console.error('Error fetching username:', error);
      }
    };
    const fetchInterval = setInterval(fetchUsername, 5000);

    return () => clearInterval(fetchInterval);
  }, [userID, db]);

  useEffect(() => {
    const initZegoUIKitPrebuilt = async () => {
      try {
        if (userID && username) {
          const token = ZegoUIKitPrebuilt.generateKitTokenForTest(
            1663711658,
            "0c697ce3a8294c2e265150bbc5707cbb",
            null,
            userID,
            username,
          );

          zp.current = ZegoUIKitPrebuilt.create(token);
          zp.current.addPlugins({ ZIM });

          document.querySelector('.videoCall').onclick = () => handleCall(ZegoUIKitPrebuilt.InvitationTypeVideoCall);
          document.querySelector('.voiceCall').onclick = () => handleCall(ZegoUIKitPrebuilt.InvitationTypeVoiceCall);
        } else {
          console.log('User ID or username not found');
        }
      } catch (error) {
        console.error('Error initializing ZegoUIKitPrebuilt:', error);
      }
    };

    initZegoUIKitPrebuilt();
  }, [userID, username]);

  const handleCall = (callType) => {
    if (!zp.current) {
      console.error('ZegoUIKitPrebuilt instance not initialized');
      return;
    }

    const callee = userId;
    if (!callee) {
      alert('userID cannot be empty!!');
      return;
    }
    const users = callee.split(',').map((id) => ({
      userID: id.trim(),
      userName: username,
    }));
    zp.current.sendCallInvitation({
      callees: users,
      callType: callType,
      timeout: 60,
    })
      .then((res) => {
        console.warn(res);
        if (res.errorInvitees.length) {
          alert('The user does not exist or is offline.');
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    const handleVisibilityChange = async () => {
      if (document.visibilityState === 'visible') {
        await updateOnlineStatus(true);
        console.log('App has come to the foreground!');
      } else {
        await updateOnlineStatus(false);
        console.log('App has gone to the background!');
        const storedUser = localStorage.getItem('user');
        if (storedUser) {
          const { userID } = JSON.parse(storedUser);
          const currentTime = new Date().toISOString();
          await updateLastOnlineTime(userID, currentTime);
        }
      }
      appState.current = document.visibilityState;
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);
  const updateOnlineStatus = async (isOnline) => {
    try {
      const auth = getAuth();
      const user = auth.currentUser;
      const userID = user ? user.uid : null;
  
      if (userID) {
        const userRef = doc(db, 'users', userID);
        console.log('Updating online status for user:', userID, 'Status:', isOnline);
        await setDoc(userRef, { online: isOnline }, { merge: true });
        console.log('Online status updated successfully.');
      } else {
        console.log('No user is currently logged in.');
      }
    } catch (error) {
      console.error('Error updating online status:', error);
    }
  };

  const updateLastOnlineTime = async (userId, currentTime) => {
    try {
      const ISTOptions = {
        timeZone: 'Asia/Kolkata',
        hour12: true,
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric'
      };

      const istTime = new Date(currentTime).toLocaleString('en-US', ISTOptions);
      const userRef = doc(db, 'users', userId);
      await setDoc(userRef, { lastOnline: istTime }, { merge: true });
    } catch (error) {
      console.error('Error updating last online time:', error);
    }
  };
  //   window.addEventListener('visibilitychange', () => {
  //     if (document.hidden) {
  //       handleAppStateChange('background');
  //     } else {
  //       handleAppStateChange('active');
  //     }
  //   });

  //   return () => {
  //     window.removeEventListener('visibilitychange', handleAppStateChange);
  //   };
  // }, [updateLastOnlineTime]);

  useEffect(() => {
    const q = query(
      collection(db, 'messages'),
      where('senderId', 'in', [userID, userId]),
      where('receiverId', 'in', [userID, userId]),
      orderBy('createdAt', 'asc')
    );

    const unsubscribe = onSnapshot(q, snapshot => {
      const fetchedMessages = snapshot.docs.map(doc => ({
        _id: doc.id,
        text: doc.data().text,
        createdAt: doc.data().createdAt.toDate(),
        user: doc.data().user,
        senderId: doc.data().senderId,
        receiverId: doc.data().receiverId,
        image: doc.data().image,
        video: doc.data().video,
        location: doc.data().location,
        audio: doc.data().audio,
        replyTo: doc.data().replyTo,
        uploading: doc.data().uploading || false,
        status: doc.data().status || 'sent',
        file: doc.data().file,
        poll: doc.data().poll,
        read: doc.data().read,
        callHistory: doc.data().callHistory || [],
        reactions: doc.data().reactions || {},
      }));
      setMessages(fetchedMessages);
      scrollToBottom();
    });

    return () => unsubscribe();
  }, [userID, userId, db]);

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const handleSend = async (newMessages = []) => {
    if (!Array.isArray(newMessages)) {
      console.error('newMessages should be an array');
      return;
    }

    try {
      const writes = newMessages.map(async m => {
        const messageId = m._id || Date.now().toString();
        const message = {
          _id: messageId,
          text: m.text || '',
          createdAt: new Date(),
          user: m.user,
          senderId: userID,
          receiverId: m.receiverId || userId,
          status: 'sent',
          read: false,
          replyTo: replyingTo ? replyingTo._id : null,
          ...(m.poll && { poll: m.poll }),
          ...(m.image && { image: m.image }),
          ...(m.video && { video: m.video }),
          ...(m.file && { file: m.file, fileName: m.fileName }),
          ...(m.location && { location: m.location }),
          reactions: m.reactions || {},
        };

        const messageRef = await addDoc(collection(db, 'messages'), message);
        await updateDoc(messageRef, { uploading: false }, { merge: true });

        const opponentUserRef = doc(db, 'users', userId);
        const opponentSnapshot = await getDoc(opponentUserRef);
        const opponentData = opponentSnapshot.data();

        if (opponentData && opponentData.online !== true && opponentData.fcmToken) {
          fetch('https://fcm.googleapis.com/fcm/send', {
            method: 'POST',
            headers: {
              'Authorization': 'key=AAAAQ_hTLLc:APA91bEw-uUjBdZ22WsmVjViEfRWPCecaY_sFyV5r2xTFIsktqX4T1SvRG2i7yrX8XFBg0LQjoElWDEGqlPNoHD88WrpBFzfWtm5-AgKP-4CVahXZd7hBKPXmrgzYUPaKWf3r4fuov_X',
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              to: opponentData.fcmToken,
              notification: {
                title: `New message from ${userName}`,
                body: m.text || 'You have a new message',
              },
              data: {
                userId: userID,
                username: userName,
              }
            })
          });
        }
      });

      await Promise.all(writes);
    } catch (error) {
      console.log(error);
    }
  };

  const getEmojiSize = (text = '') => {
    // Regular expression to match emojis
    const emojiRegex = /(?:[\u2700-\u27BF]|(?:\uD83C[\uDC00-\uDFFF])|(?:\uD83D[\uDC00-\uDFFF])|(?:\uD83E[\uDD00-\uDFFF]))/g;
    // Regular expression to match any non-emoji character
    const nonEmojiRegex = /[^\u2700-\u27BF\uD83C\uDC00-\uDFFF\uD83D\uDC00-\uDFFF\uD83E\uDD00-\uDFFF]/;
  
    // Check if there are any non-emoji characters in the text
    const hasNonEmojiText = nonEmojiRegex.test(text);
  
    // Match emojis in the text
    const emojis = text.match(emojiRegex);
  
    // If there's non-emoji text, do not increase the size of the emojis
    if (hasNonEmojiText) {
      return '';
    }
  
    // Return the appropriate class based on the number of emojis
    if (emojis && emojis.length <= 4) {
      return ['large-emoji', 'medium-emoji', 'small-emoji', 'tiny-emoji'][emojis.length - 1];
    }
    
    return '';
  };
  
  

  const createLink = (text = '') => {
    const urlRegex = /https?:\/\/[^\s]+/g;
    return text.split(urlRegex).map((part, index, array) => {
      const match = text.match(urlRegex);
      return (
        <React.Fragment key={index}>
          {part}
          {index < array.length - 1 && match && match[0] && (
            <a href={match[0]} target="_blank" rel="noopener noreferrer">
              {match[0]}
            </a>
          )}
        </React.Fragment>
      );
    });
  };

  const requestBlob = uri => {
    return new Promise((resolve, reject) => {
      let xhr = new XMLHttpRequest();
      xhr.onload = () => resolve(xhr.response);
      xhr.onerror = () => reject(new TypeError('Network request failed'));
      xhr.responseType = 'blob';

      xhr.open('GET', uri, true);
      xhr.send(null);
    });
  };

  const _onImageChange = async event => {
    const {uri, linkUri, mime, data} = event.nativeEvent;
    console.log('Image selected:', uri, linkUri, mime);

    if (linkUri) {
      // Handle linkUri as a direct URL
      const updatedMessage = {
        _id: Date.now(),
        text: '',
        createdAt: new Date(),
        user: {_id: userID},
        image: linkUri,
        uploading: false,
        senderId: userID,
        receiverId: userId,
      };
      handleSend([updatedMessage]);
    } else if (uri) {
      try {
        const body = await requestBlob(uri);
        const ref = firebaseRef(storage, `Chat_Gif/${userID}-${Date.now()}`);
        const task = uploadBytesResumable(ref, body);
        task.on(
          'state_changed',
          snapshot => {
            const percentage =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 1000;
          },
          error => {
            console.log('Error in uploading image:', error);
          },
          async () => {
            const url = await getDownloadURL(ref);
            const updatedMessage = {
              _id: Date.now(),
              text: '',
              createdAt: new Date(),
              user: {_id: userID},
              image: url,
              uploading: false,
              senderId: userID,
              receiverId: userId,
            };
            handleSend([updatedMessage]);
          },
        );
      } catch (error) {
        console.log('Error in uploading image:', error);
      }
      // console.log('Image selected IN ELSE:', uri);
    }
  };
  const sendMessage = () => {
    if (newMessage.startsWith('data:image/gif;base64,')) {
      // GIF detected in newMessage
      setNewMessage(newMessage);
      setNewMessage(''); 
    } else {
    const newMsg = {
      text: newMessage || '',
      user: { name: userName, senderId: userID },
      senderId: userID,
      receiverId: userId,
      createdAt: new Date(),
    };
    handleSend([newMsg]);
    setNewMessage('');
    setReplyingTo(null);
   } 
  };

  const onEmojiClick = (emojiObject, event) => {
    const symbol = emojiObject?.emoji;
    console.log("Symbol: ", symbol);
    if (symbol) {
      setNewMessage(prevMessage => prevMessage + symbol);
    } else {
      console.error('Failed to add emoji: ', emojiObject);
    }
  };

  const handlers = useSwipeable({
    onSwipedRight: (eventData) => {
      const messageId = eventData.event.target.closest('.messageBubble')?.dataset.msgId;
      if (messageId) {
        setReplyingTo(messages.find(m => m._id === messageId));
        const element = document.querySelector(`[data-msg-id="${messageId}"]`);
        if (element) {
          element.classList.add('swipe-right');
          setTimeout(() => element.classList.remove('swipe-right'), 500);
        }
      }
    },
    preventDefaultTouchmoveEvent: true,
    trackMouse: true
  });

  const handleDeleteDatabase = async () => {
    const confirmDelete = window.confirm('Are you sure you want to delete the entire database?');
    if (confirmDelete) {
      try {
        const messagesQuery = query(
          collection(db, 'messages'),
          where('senderId', 'in', [userID, userId]),
          where('receiverId', 'in', [userID, userId]),
        );
        const querySnapshot = await getDocs(messagesQuery);
        if (!querySnapshot.empty) {
          const batch = writeBatch(db);
          querySnapshot.forEach(doc => {
            batch.delete(doc.ref);
          });
          await batch.commit();
          alert('Database deleted successfully');
        } else {
          alert('No messages to delete');
        }
      } catch (error) {
        console.error('Error deleting database:', error);
      }
    }
  };

  const handleShowModal = (e, message) => {
    const rect = e.currentTarget.getBoundingClientRect();
    setModalPosition({
      top: rect.top + window.scrollY,
      left: (rect.left + window.scrollX) * 0.3,
    });
    setSelectedMessage(message);
    setShowModal(true);
  };

  const handleAction = async (action) => {
    if (action === 'reply') {
      setReplyingTo(selectedMessage);
      setShowModal(false);
    }
    if (action === 'delete') {
      try {
        console.log('Attempting to delete message:', selectedMessage._id);
        setShowModal(false);
        if (!selectedMessage._id) {
          console.error('Error: Message ID is undefined, cannot delete.');
          setShowModal(false);
          return;
        }
        const messageRef = doc(db, 'messages', selectedMessage._id);
        await deleteDoc(messageRef);
        setShowModal(false);
        console.log('Message deleted successfully:', selectedMessage._id);
      } catch (error) {
        console.error('Error deleting message:', error);
      }
    }
    if (action === 'copy') {
      navigator.clipboard.writeText(selectedMessage.text).then(() => {
        console.log('Text copied to clipboard:', selectedMessage.text);
      }).catch(err => {
        console.error('Failed to copy text:', err);
      });
    }
    if (action === 'reaction') {
      console.log('Reacting to message');
    }
    if (action === 'more-reactions') {
      setShowMoreReactions(!showMoreReactions);
    }
    console.log(`${action} action on message:`, selectedMessage);
    setShowModal(false);
  };

  const handleReaction = async (reaction) => {
    setShowMoreReactions(false);
    setShowModal(false);
    try {
      if (selectedMessage) {
        const messageRef = doc(db, 'messages', selectedMessage._id);
        const messageSnap = await getDoc(messageRef);
        const messageData = messageSnap.data();

        const updatedReactions = {
          ...messageData.reactions,
          [userID]: reaction,
        };

        await updateDoc(messageRef, { reactions: updatedReactions });
        setShowMoreReactions(false);
      }
    } catch (error) {
      console.error('Error adding reaction:', error);
    }
  };

  const renderMoreReactions = () => (
    <Modal
      isOpen={showMoreReactions}
      onRequestClose={() => setShowMoreReactions(false)}
      style={customStyles}
    >
      <Picker onEmojiClick={(emojiObject) => handleReaction(emojiObject.emoji)} />
    </Modal>
  );

  const renderLocation = (location) => {
    if (!location) return null;
    const { latitude, longitude } = location;
    const mapUrl = `https://maps.google.com/maps?q=${latitude},${longitude}&z=15&output=embed`;
    return (
      <iframe
        src={mapUrl}
        width="100%"
        height="200"
        frameBorder="0"
        style={{ border: 0 }}
        allowFullScreen
        aria-hidden="false"
        tabIndex="0"
      />
    );
  };

  const fetchOpponentProfile = async () => {
    try {
      const userRef = doc(db, 'users', userId);
      onSnapshot(userRef, (doc) => {
        if (doc.exists()) {
          const userData = doc.data();
          setOpponentProfileImage(userData.profilePic || User);
          setOpponentOnlineStatus(userData.online ? 'online' : 'offline');
  
          if (!userData.online && userData.lastOnline) {
            let lastSeenDate;
            console.log('Raw lastOnline value:', userData.lastOnline);
            if (userData.lastOnline.toDate) {
              lastSeenDate = userData.lastOnline.toDate();
            } else if (typeof userData.lastOnline === 'string') {
              const currentDateString = new Date().toISOString().split('T')[0];
              lastSeenDate = new Date(`${currentDateString} ${userData.lastOnline}`);
            } else {
              console.error('Unknown date format:', userData.lastOnline);
              setLastOnline('Unknown');
              return;
            }
            console.log('Parsed lastSeenDate:', lastSeenDate, 'Timestamp:', lastSeenDate.getTime());
            if (!isNaN(lastSeenDate.getTime())) {
              const options = { hour: '2-digit', minute: '2-digit', hour12: true };
              const formattedLastSeen = lastSeenDate.toLocaleTimeString('en-US', options);
              setLastOnline(formattedLastSeen);
            } else {
              console.error('Invalid date:', userData.lastOnline);
              setLastOnline('Unknown');
            }
          }
        }
      });
    } catch (error) {
      console.error('Error fetching opponent profile:', error);
    }
  };
  const [gifInputActive, setGifInputActive] = useState(false);
  const inputRef = useRef(null); // Reference to the input element
  useEffect(() => {
    fetchOpponentProfile();
  }, [userId]);
  useEffect(() => {
    // Detect when the GIF input is activated or deactivated
    const handleFocus = () => {
      if (inputRef.current && newMessage === '') {
        setGifInputActive(true);
      }
    };
    const handleBlur = () => setGifInputActive(false);

    const inputElement = inputRef.current;
    inputElement.addEventListener('focus', handleFocus);
    inputElement.addEventListener('blur', handleBlur);

    return () => {
      inputElement.removeEventListener('focus', handleFocus);
      inputElement.removeEventListener('blur', handleBlur);
    };
  }, [newMessage]);

  useEffect(() => {
    if (gifInputActive && newMessage.startsWith('data:image/gif;base64,')) {
      alert(console.log(newMessage)) // Display newMessage as an alert
    console.log('Sending GIF:', newMessage);
      const updatedMessage = {
        _id: Date.now(),
        text: '',
        createdAt: new Date(),
        user: { _id: userID },
        image: newMessage, 
        uploading: false,
        senderId: userID,
        receiverId: userId,
      };
      handleSend([updatedMessage]);
      setNewMessage(''); 
    } 
  }, [gifInputActive, newMessage, handleSend, userID, userId]);


  const handlePollVote = async (messageId, option) => {
    try {
      const messageRef = doc(db, 'messages', messageId);
      const messageSnap = await getDoc(messageRef);
      const messageData = messageSnap.data();

      const updatedPoll = messageData.poll.map((opt) => {
        const newOpt = { ...opt };
        if (newOpt.option === option) {
          if (newOpt.voters.includes(userID)) {
            return newOpt;
          } else {
            newOpt.votes += 1
            newOpt.voters.push(userID);
          }
        } else if (newOpt.voters.includes(userID)) {
          newOpt.votes -= 1;
          newOpt.voters = newOpt.voters.filter((voter) => voter !== userID);
        }
        return newOpt;
      });

      await updateDoc(messageRef, { poll: updatedPoll });
    } catch (error) {
      console.error('Error updating poll:', error);
    }
  };
  useEffect(() => {
    if (userId) {  // Ensure there is a chat opened
      markMessagesAsRead();
    }
  }, [userId, userID, db]); // Re-run when the user or chat changes

  const markMessagesAsRead = async () => {
    const queryUnread = query(
      collection(db, "messages"),
      where("receiverId", "==", userID),
      where("senderId", "==", userId),
      where("read", "==", false)
    );

    const querySnapshot = await getDocs(queryUnread);
    const batch = writeBatch(db);
    querySnapshot.forEach((document) => {
      batch.update(doc(db, "messages", document.id), { read: true });
    });
    await batch.commit();
  };

  return (
    <div className="chatPageContainer" style={{ backgroundImage: `url(${Themes})`, backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat' }}>
      <div className="header">
        <div className="headerLeft">
          <img src={arrowLeft} alt="Back" className="backArrow" onClick={() => navigate(-1)} />
          <img src={opponentProfileImage} alt="Opponent" className="profileImage" />
          <div className="userInfo">
            <h2 className="name" onClick={() => navigate('/ReciverProfile', {
  state: {
    userId,
    username
  }
})}>{username}</h2>
            {opponentOnlineStatus === 'online' ? (
              <span className="status online">online</span>
            ) : (
              <span className="status offline">Last seen: {lastOnline}</span>
            )}
          </div>
        </div>
        <div className="headerIcons">
          <img src={Video} alt="Video" className="headerIcon videoCall" onClick={() => handleCall(ZegoUIKitPrebuilt.InvitationTypeVideoCall)} />
          <img src={Voice} alt="Voice" className="headerIcon voiceCall" onClick={() => handleCall(ZegoUIKitPrebuilt.InvitationTypeVoiceCall)} />
          <img src={Dots} alt="More" className="headerIcon" onClick={() => setShowDotsMenuModal(true)} />
        </div>
      </div>
      <div className="messagesContainer" {...handlers}>
        <TransitionGroup style={{ display: 'contents' }}>
          {messages.map((msg) => (
            <CSSTransition key={msg._id} timeout={300} classNames="message">
              <div className={`messageBubble ${msg.senderId === userID ? 'sent' : 'received'}`} data-msg-id={msg._id} onContextMenu={(e) => { e.preventDefault(); handleShowModal(e, msg); }}>
                {msg.replyTo && (
                  <div className="replyingTo">
                    <span>Reply to: </span>
                    <p>{messages.find(m => m._id === msg.replyTo)?.text}</p>
                  </div>
                )}
                <div className={`messageContent ${getEmojiSize(msg.text)}`}>
                  <p>{createLink(msg.text)}</p>
                  {msg.image && <img src={msg.image} alt="attachment" />}
                  {msg.video && <video src={msg.video} controls />}
                  {msg.audio && <audio src={msg.audio} controls />}
                  {msg.file && <a href={msg.file} download>Document</a>}
                  {renderLocation(msg.location)}
                  {msg.poll && Array.isArray(msg.poll) && (
                    <div className="poll">
                      {msg.poll.map((option, index) => (
                        <button key={index} onClick={() => handlePollVote(msg._id, option.option)} className='pollOption'>
                          {option.option} ({option.votes} votes)
                        </button>
                      ))}
                    </div>
                  )}
                  <span className= {`timestamp ${getEmojiSize(msg.time)}`}>{msg.createdAt.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
                  {msg.senderId === userID && (
                  msg.read ? (
                    <span className="messageStatus"><img src={Check} alt="Read"  className='tick'/></span>  // Blue ticks for read messages
                  ) : (
                    <span className="messageStatus"><img src={DeliveredIcon} alt="Sent/Delivered" className='tick'/></span>  // Grey ticks for sent/delivered messages
                  )
                )}
                  </span>
                  <div className="reactions">
                    {msg.reactions && Object.values(msg.reactions).map((reaction, index) => (
                      <span key={index} className="reaction">{reaction}</span>
                    ))}
                  </div>
                </div>
              </div>
            </CSSTransition>
          ))}
        </TransitionGroup>
        <div ref={messagesEndRef} />
      </div>
      {replyingTo && (
        <div className="replyingTo">
          <span>Replying to: {replyingTo.text}</span>
          {replyingTo.image && <img src={replyingTo.image} alt="attachment" className='replyPreviewImage' />}
          {replyingTo.video && <video src={replyingTo.video} className='replyPreviewVideo' controls />}
          {replyingTo.audio && <audio src={replyingTo.audio} controls />}
          {replyingTo.file && <a href={replyingTo.file} download>Document</a>}
          <button onClick={() => setReplyingTo(null)}>Cancel</button>
        </div>
      )}
      <div className="inputContainer1 ">
        <img src={attachIcon} alt="Attach" className="inputIcon1" onClick={() => setShowAttachmentModal(true)} />
        <input
          type="text"
          ref={inputRef}
          value={newMessage}
          onChange={(e) => {
            if (e.target.value.trim() === '') {
              setIsTyping(false);
              setNewMessage('');
            } else {
              setIsTyping(true);
              setNewMessage(e.target.value);
            }
          }}
          placeholder="Type a message..."
        />
        <input
        onChange={_onImageChange}
        style={{display:'none'}}
        />
        {!newMessage && <img src={cameraIcon} alt="Camera" className="inputIcon cameraIcon" />}
        <img src={emojiIcon} alt="Emoji" className="inputIcon" onClick={() => setShowEmojiPicker(!showEmojiPicker)} />
        {showEmojiPicker && <Picker onEmojiClick={onEmojiClick} style={{ position: 'absolute', bottom: '120px', right: '20px' }} />}
        <button onClick={sendMessage} className='send'>Send</button>
      </div>
      <DotsMenuModal show={showDotsMenuModal} onClose={() => setShowDotsMenuModal(false)} onDelete={handleDeleteDatabase} />
      <AttachmentModal
        show={showAttachmentModal}
        onClose={() => setShowAttachmentModal(false)}
        handleSend={handleSend}
        userName={userName}
        userID={userID}
        userId={userId}
      />
      {showModal && (
        <div className="modal1" style={{ top: modalPosition.top, left: modalPosition.left }}  >
          <div className="modal1-reactions">
            <span onClick={() => handleReaction('😊')}>😊</span>
            <span onClick={() => handleReaction('👍')}>👍</span>
            <span onClick={() => handleReaction('😢')}>😢</span>
            <span onClick={() => handleReaction('🔥')}>🔥</span>
            <span onClick={() => { setShowMoreReactions(true); setShowModal(false) }}>➕</span>
          </div>
          <div className="modal1-actions" >
            <div onClick={() => handleAction('reply')} className="modal1-action-item">
              <img src={replyIcon} alt="Reply" />
              <span>Reply</span>
            </div>
            <div onClick={() => handleAction('copy')} className="modal1-action-item">
              <img src={copyIcon} alt="Copy" />
              <span>Copy Text</span>
            </div>
            <div onClick={() => handleAction('forward')} className="modal1-action-item">
              <img src={forwardIcon} alt="Forward" />
              <span>Forward</span>
            </div>
            <div onClick={() => handleAction('delete')} className="modal1-action-item delete">
              <img src={deleteIcon} alt="Delete" />
              <span>Delete</span>
            </div>
          </div>
        </div>
      )}
      {renderMoreReactions()}
    </div>
  );
};

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    transform: 'translate(-50%, -50%)',
  },
};

export default ChatPage;
