import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  doc,
  getDoc,
  updateDoc
} from 'firebase/firestore';
import { getAuth, signOut } from 'firebase/auth';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { db, storage } from '../Components/firebaseConfig';
import userImage from '../Assets/7309681.jpg';
import ArrowLeft from '../Assets/left-chevron.png';
import Modal from 'react-modal';
import '../Chtas-Style/SenderProfile.css';

const SenderProfile = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { userID } = location.state;
  const [userDetails, setUserDetails] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [bio, setBio] = useState('');
  const [isEditingBio, setIsEditingBio] = useState(false);
  const [isMenuVisible, setIsMenuVisible] = useState(false);

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const userDoc = await getDoc(doc(db, 'users', userID));
        if (userDoc.exists()) {
          const userData = userDoc.data();
          setUserDetails(userData);
          setBio(userData.bio || '');
        } else {
          console.log('No such document!');
        }
      } catch (error) {
        console.error('Error fetching user details:', error);
      }
    };

    if (userID) {
      fetchUserDetails();
    }
  }, [userID]);

  const selectImage = async (event) => {
    const file = event.target.files[0];
    if (file) {
      uploadImage(file);
    }
  };

  const uploadImage = async (file) => {
    setUploading(true);
    const storageRef = ref(storage, `profile_pics/${userID}/${file.name}`);

    try {
      await uploadBytes(storageRef, file);
      const downloadURL = await getDownloadURL(storageRef);
      await updateDoc(doc(db, 'users', userID), { profilePic: downloadURL });
      setUserDetails(prevState => ({ ...prevState, profilePic: downloadURL }));
      alert('Profile picture updated successfully!');
    } catch (error) {
      console.error('Error uploading image: ', error);
      alert('Error uploading image');
    } finally {
      setUploading(false);
    }
  };

  const selectTheme = async (event) => {
    const file = event.target.files[0];
    if (file) {
      selectThemeImage(file);
    }
  };

  const selectThemeImage = async (file) => {
    setUploading(true);
    const storageRef = ref(storage, `Themes_Chat/${userID}/${file.name}`);

    try {
      await uploadBytes(storageRef, file);
      const downloadURL = await getDownloadURL(storageRef);
      await updateDoc(doc(db, 'users', userID), { ThemesChat: downloadURL });
      setUserDetails(prevState => ({ ...prevState, ThemesChat: downloadURL }));
      alert('Theme updated successfully!');
    } catch (error) {
      console.error('Error uploading image: ', error);
      alert('Error uploading image');
    } finally {
      setUploading(false);
    }
  };

  const handleChangePassword = () => {
    navigate('/Set-pin');
  };

  const logout = async () => {
    try {
      const auth = getAuth();
      await signOut(auth);
      localStorage.clear();
      navigate('/');
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };

  const needHelp = () => {
    navigate('/NeedHelp');
  };

  const updateBio = async () => {
    try {
      await updateDoc(doc(db, 'users', userID), { bio });
      setUserDetails(prevState => ({ ...prevState, bio }));
      setIsEditingBio(false);
      alert('Bio updated successfully!');
      setIsMenuVisible(false);
    } catch (error) {
      console.error('Error updating bio:', error);
      alert('Error updating bio');
    }
  };

  if (!userDetails) {
    return (
      <div className="container">
        <p>Loading user details...</p>
      </div>
    );
  }

  return (
    <div className="container1">
      <button className="back-button" onClick={() => navigate(-1)}>
        <img src={ArrowLeft} alt="Back" className="back-button-image" />
      </button>
      <div className="profile-header">
        <input type="file" id="profile-pic-upload" onChange={selectImage} style={{ display: 'none' }} />
        <label htmlFor="profile-pic-upload" className="profile-pic-label">
          <img src={userDetails.profilePic || userImage} alt="Profile" className="profile-pic" />
        </label>
        <h2>{userDetails.name}</h2>
        <div className="bio-container">
          <p className="bio">{userDetails.bio || 'Add a bio'}</p>
          <button className="edit-button" onClick={() => setIsMenuVisible(true)}>Edit</button>
        </div>
        <p>{userDetails.email}</p>
      </div>
      <div className="actions">
        <input type="file" id="theme-upload" onChange={selectTheme} style={{ display: 'none' }} />
        <label htmlFor="theme-upload" className="action-button">Change Chat Theme</label>
        <button className="action-button" onClick={handleChangePassword}>Change Password</button>
        <button className="action-button" onClick={logout}>Logout</button>
        <button className="action-button" onClick={needHelp}>Need Help?</button>
      </div>

      <Modal
        isOpen={isMenuVisible}
        onRequestClose={() => setIsMenuVisible(false)}
        contentLabel="Edit Bio"
        className="modal"
        overlayClassName="modal-overlay"
      >
        <div className="modal-content">
          <textarea
            className="bio-input"
            value={bio}
            onChange={(e) => setBio(e.target.value)}
            placeholder="Enter your bio"
            rows="4"
          />
          <button className="update-bio-button" onClick={updateBio}>Update Bio</button>
        </div>
      </Modal>
    </div>
  );
};

export default SenderProfile;
