import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { getFirestore, doc, getDoc } from 'firebase/firestore';
import Modal from 'react-modal';
import UserImage from '../Assets/7309681.jpg';
import ArrowLeft from '../Assets/left-chevron.png';
import '../Chtas-Style/ReceiverProfile.css';

const ReceiverProfile = () => {
  const location = useLocation();
  const { state } = location;
  const userId = state?.userId;
  const username = state?.username;
  const navigate = useNavigate();
  const [userDetails, setUserDetails] = useState(null);
  const [opponentBio, setOpponentBio] = useState(null);
  const [opponentProfilePic, setOpponentProfilePic] = useState(null);
  const [fullScreenImage, setFullScreenImage] = useState(null);
  const db = getFirestore();

  useEffect(() => {
    if (!userId) {
      console.error('User ID is missing');
      return;
    }

    const fetchOpponentBio = async () => {
      try {
        const userRef = doc(db, 'users', userId);
        const userSnapshot = await getDoc(userRef);
        if (userSnapshot.exists()) {
          const userData = userSnapshot.data();
          setOpponentBio(userData.bio);
        } else {
          console.log('Opponent user not found');
        }
      } catch (error) {
        console.error('Error fetching opponent bio:', error);
      }
    };

    fetchOpponentBio();
  }, [userId, db]);

  useEffect(() => {
    if (!userId) {
      console.error('User ID is missing');
      return;
    }

    const fetchOpponentProfilePic = async () => {
      try {
        const userRef = doc(db, 'users', userId);
        const userSnapshot = await getDoc(userRef);
        if (userSnapshot.exists()) {
          const userData = userSnapshot.data();
          setOpponentProfilePic(userData.profilePic);
        } else {
          console.log('Opponent user not found');
        }
      } catch (error) {
        console.error('Error fetching opponent profile picture:', error);
      }
    };

    fetchOpponentProfilePic();
  }, [userId, db]);

  useEffect(() => {
    if (!userId) {
      console.error('User ID is missing');
      return;
    }

    const fetchUserDetails = async () => {
      try {
        const userDoc = await getDoc(doc(db, 'users', userId));
        if (userDoc.exists()) {
          setUserDetails(userDoc.data());
        } else {
          console.log('No such document!');
        }
      } catch (error) {
        console.error('Error fetching user details:', error);
      }
    };

    fetchUserDetails();
  }, [userId, db]);

  if (!userDetails) {
    return (
      <div className="container">
        <p className="loadingText">Loading user details...</p>
      </div>
    );
  }

  return (
    <div className="container1">
      <button className="backButton" onClick={() => navigate(-1)}>
        <img src={ArrowLeft} className="backButtonImage" alt="Back" />
      </button>
      <button onClick={() => setFullScreenImage(opponentProfilePic)}
        className='imagebutton'>
        <img
          src={opponentProfilePic ? opponentProfilePic : UserImage}
          className="profileImage1"
          alt="Profile"
        />
      </button>
      <h1 className="name1">{userDetails.name}</h1>
      <p className="email">{userDetails.email}</p>
      <p className="email">{opponentBio}</p>
      <div className="optionsContainer">
        <button className="optionButton" onClick={() => navigate('/chat' , {state: {
    userId,
    username
  }})}>
          <img src={require('../Assets/messages.png')} className="optionIcon" alt="Message" />
          <span className="optionText">Message</span>
        </button>
      </div>
      <Modal
        isOpen={!!fullScreenImage}
        onRequestClose={() => setFullScreenImage(null)}
        className="fullScreenModal"
      >
        <div className="fullScreenContainer">
          <button className="closeButton" onClick={() => setFullScreenImage(null)}>
            &#x2715;
          </button>
          <img src={fullScreenImage} className="fullScreenImage" alt="Full Screen" />
        </div>
      </Modal>
    </div>
  );
};

export default ReceiverProfile;
