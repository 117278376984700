import React, { useState, useEffect } from 'react';
import {
  getFirestore,
  collection,
  query,
  where,
  getDocs,
  addDoc,
} from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import user from '../Assets/7309681.jpg';
import Angleleft from '../Assets/left-chevron.png';
import SearchIcon from '../Assets/Se.png';
import Profile from '../Assets/7309681.jpg';
import '../Chtas-Style/UserSearchScreen.css';

const UserSearchScreen = () => {
  const [userID, setUserID] = useState(null);
  const [searchResults, setSearchResults] = useState([]);
  const [searchError, setSearchError] = useState('');
  const [searchText, setSearchText] = useState('');
  const [isSearchBarVisible, setIsSearchBarVisible] = useState(false);
  const db = getFirestore();
  const auth = getAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserID = async () => {
      try {
        const storedUser = localStorage.getItem('user');
        const { userID } = JSON.parse(storedUser);
        console.log('Retrieved User ID:', userID);
        if (userID) {
          setUserID(userID);
        } else {
          console.log('User ID not found in localStorage');
        }
      } catch (error) {
        console.error('Error fetching user ID from localStorage:', error);
      }
    };
    fetchUserID();
  }, []);

  useEffect(() => {
    searchUsers('');
  }, []);

  const searchUsers = async (text) => {
    try {
      setSearchError('');
      const usersRef = collection(db, 'users');
      let q;
      if (text.trim() === '') {
        q = query(usersRef);
      } else {
        q = query(usersRef, where('name', '>=', text), where('name', '<=', text + '\uf8ff'));
      }
      const querySnapshot = await getDocs(q);
      const results = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
      }));
      if (results.length === 0) {
        setSearchError('No user found.');
      }
      setSearchResults(results);
    } catch (error) {
      console.error('Error searching users:', error);
      setSearchError('Error searching users. Please try again.');
    }
  };

  useEffect(() => {
    searchUsers(searchText);
  }, [searchText]);

  const addToRecentChats = async (item) => {
    try {
      if (!userID) {
        console.error('User ID is missing.');
        return;
      }
      const recentChatsRef = collection(db, 'recentChats');
      const querySnapshot = await getDocs(
        query(
          recentChatsRef,
          where('userId', '==', userID),
          where('chatUserId', '==', item.id),
        ),
      );
      if (!querySnapshot.empty) {
        console.log('Recent chat entry already exists for this user.');
        return;
      }

      await addDoc(recentChatsRef, {
        userId: userID,
        chatUserId: item.id,
        username: item.name,
        email: item.email,
      });

      console.log('Recent chat user added successfully.');
    } catch (error) {
      console.error('Error adding recent chat user:', error);
    }
  };

  const handleUserProfile = () => {
    navigate('/SenderProfile', { state: { userID } });
  };

  const toggleSearchBar = () => {
    if (isSearchBarVisible) {
      setSearchText(''); // Clear the search text when hiding the search bar
      setSearchResults([]); // Clear the search results when hiding the search bar
    }
    setIsSearchBarVisible(!isSearchBarVisible);
  };

  return (
    <div className="user-search-screen">
      <header className="header">
        <button onClick={() => navigate(-1)} className="header-back-button">
          <img src={Angleleft} className="header-back-icon" alt="Back" />
        </button>
        <span className="header-title">Select Users</span>
        <div className="header-actions">
          <button onClick={toggleSearchBar} className="header-button">
            <img src={SearchIcon} className="header-icon" alt="Search" />
          </button>
          <button onClick={handleUserProfile} className="header-button">
            <img src={Profile} className="header-profile-icon" alt="Profile" />
          </button>
        </div>
      </header>
      {isSearchBarVisible && (
        <div className="search-container">
          <input
            className="search-input"
            placeholder="Search Users"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
          />
        </div>
      )}
      <div className="content">
        {searchError ? (
          <span className="error-text">{searchError}</span>
        ) : (
          <ul className="user-list">
            {searchResults.map(item => (
              <li key={item.id} className="user-item">
                <div
                  className="chat-item-container"
                  onClick={() => {
                    addToRecentChats(item);
                    navigate('/Chat', {
                      state: {
                        userId: item.id,
                        userMail: item.email,
                        username: item.name,
                      },
                    });
                  }}
                >
                  <div className="user-details-container">
                    <img src={item.profilePic ? item.profilePic : user} className="user-image" alt="User" />
                    <span className="user-name">{item.name}</span>
                  </div>
                  <span className="last-message-text">Last Message</span>
                </div>
                <span className="chat-now-text">Chat Now</span>
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

export default UserSearchScreen;
