import React, { useState, useRef, useEffect } from 'react';
import { Alert } from 'react';
import { useNavigate } from 'react-router-dom';
import { doc, getDoc, getFirestore } from 'firebase/firestore';
import app from './firebaseConfig';
import PinInput from './PinInput';
import '../Styles/Enterpin.css';
import Logo from '../Assets/Logo.png';
const EnterPin = () => {
    const navigate = useNavigate();
    const db = getFirestore(app);
    const [chatPin, setChatPin] = useState(['', '', '', '']);
    const chatPinInputs = useRef([]);
    const [userId, setUserId] = useState(null);
  
    useEffect(() => {
      const getUserId = async () => {
        try {
          const user = localStorage.getItem('user');
          if (user) {
            const { userID } = JSON.parse(user);
            setUserId(userID);
          }
        } catch (error) {
          console.error('Error retrieving user ID:', error);
        }
      };
  
      getUserId();
    }, []);
  
    const handlePinChange = (index, value) => {
      const newPin = [...chatPin];
      newPin[index] = value;
      setChatPin(newPin);
  
      if (value !== '' && index < chatPin.length - 1) {
        chatPinInputs.current[index + 1].focus();
      }
    };
  
    const handlePinBackspace = (index) => {
      if (index > 0) {
        chatPinInputs.current[index - 1].focus();
      }
    };
  
    const clearPins = () => {
      setChatPin(['', '', '', '']);
      chatPinInputs.current.forEach(input => {
        if (input) input.value = '';
      });
    };
  
    const verifyPins = async () => {
      try {
        if (!userId) {
          throw new Error('User ID not found. Please login again.');
        }
  
        const userDoc = doc(db, 'users', userId);
        const userSnapshot = await getDoc(userDoc);
  
        if (!userSnapshot.exists()) {
          throw new Error('User document not found.');
        }
  
        const userData = userSnapshot.data();
  
        if (!userData.chatPin) {
          throw new Error('PINs not found in database.');
        }
  
        if (chatPin.join('') === userData.chatPin) {
          navigate('/chat-home');
          clearPins();
          return;
        }
        if (chatPin.join('') === userData.appPin) {
          navigate('/app-home');
          clearPins();
          return;
        }
  
        throw new Error('Invalid PIN entered.');
      } catch (error) {
        console.error('Error verifying pins:', error);
        alert('Error', error.message);
        clearPins();
      }
    };

  return (
    <div className="containerEnterPin">
      {/* <img src="../Images/Background.jpeg" className="image" alt="background" /> */}
      <div className="overlay1">
        <img src={Logo} className="logoEnterPin" alt="logoEnterPin" />
        <h1 className="heading">CIT'S</h1>
        <div className="formInput">
          <h2 className="text">Enter PIN</h2>
          <div className="buttonEnterPin">
            <div className="inputContainer">
              {chatPin.map((digit, index) => (
                <PinInput
                  key={index}
                  ref={ref => (chatPinInputs.current[index] = ref)}
                  value={digit}
                  onChange={value => handlePinChange(index, value)}
                  onBackspace={() => handlePinBackspace(index)}
                />
              ))}
            </div>
            <button className="enterButton" onClick={verifyPins}>
              <span className="buttonText">Verify Pin</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EnterPin;
