import { initializeApp } from 'firebase/app';
import { getStorage } from "firebase/storage";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyCGXWoFyB02a9YS9VHOwkNffzW4wf6ZLPc",
  authDomain: "mahicits.firebaseapp.com",
  projectId: "mahicits",
  storageBucket: "mahicits.appspot.com",
  messagingSenderId: "291929009335",
  appId: "1:291929009335:web:127b0a3e5d3ceb2c587132"
};

const app = initializeApp(firebaseConfig);

// Initialize Firestore and Storage
const db = getFirestore(app);
const storage = getStorage(app);

// eslint-disable-next-line import/no-anonymous-default-export
export default app
export { db, storage };