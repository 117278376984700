import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getFirestore, doc, getDoc } from 'firebase/firestore';
import profileImg from '../images/profile.png'; // Importing images
import img27881 from '../images/27881.jpg';
import img40907 from '../images/40907.jpg';
import imgDoctor from '../images/doctor.jpg';
import img180309 from '../images/180309-OWXP1U-827.jpg';
import imgFaceRecognition from '../images/face-recognition-personal-identification-collage.jpg';
import imgEyeDoctor from '../images/eyedoctor.jpg';
import imgEyeDoctor2 from '../images/eyedoctor2.jpeg';
import imgEyeDoctor3 from '../images/eyedoctor3.jpg';
import imgSunglasses from '../images/sunglasses.jpg';
import imgEyeglass from '../images/eyeglass.jpg';
import imgEyeScan from '../images/EyeScan.jpg';
import imgDoctorConsult from '../images/doctor.jpg';
import './HomeScreen.css'; // Assuming you have a CSS file for styles

const HomeScreen = () => {
  const db = getFirestore();
  const [userID, setUserID] = useState(null);
  const [userName, setUserName] = useState(null);
  const [userEmail, setUserEmail] = useState(null);
  const navigate = useNavigate();

  const carouselData = [
    { image: img27881 },
    { image: img40907 },
    { image: imgDoctor },
    { image: img180309 },
    { image: imgFaceRecognition },
  ];

  const doctorsData = [
    { image: imgEyeDoctor, text: 'DR. Sanjay Dhawan', price: 'Rs 399/- only' },
    { image: imgEyeDoctor2, text: 'DR. Parul Sharma', price: 'Rs 399/- only' },
    { image: imgEyeDoctor3, text: 'DR. Purnima Sood', price: 'Rs 399/- only' },
  ];

  useEffect(() => {
    fetchUserData();
  }, []);

  useEffect(() => {
    if (userID) {
      fetchUsername(userID);
    }
  }, [userID]);

  const fetchUsername = async (userID) => {
    try {
      if (!userID) {
        throw new Error("userID is undefined or null");
      }

      console.log('Fetching data for userID:', userID); // Logging the userID
      const userRef = doc(db, 'users', userID);
      const userSnapshot = await getDoc(userRef);
      if (userSnapshot.exists()) {
        const userData = userSnapshot.data();
        if (userData && userData.name) {
          setUserName(userData.name);
        } else {
          console.log('User name not found in user data');
        }
      } else {
        console.log('User not found');
      }
    } catch (error) {
      console.error('Error fetching username:', error);
    }
  };

  const fetchUserData = async () => {
    try {
      const user = localStorage.getItem('user');
      if (user) {
        const userData = JSON.parse(user);
        console.log("userData", userData); // Logging the user data
        if (userData.userID) {
          setUserID(userData.userID);
        } else {
          console.error('userID is undefined in userData'); // Added logging
        }
        if (userData.email) {
          setUserEmail(userData.email);
        } else {
          console.error('email is undefined in userData'); // Added logging
        }
      } else {
        console.log('No user data found in localStorage'); // Added logging
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  const renderTrendingDoctors = () => {
    return doctorsData.map((doctor, index) => (
      <div key={index} className="doctor-card">
        <img src={doctor.image} alt={doctor.text} className="doctor-image" />
        <div>
          <p className="doctor-name">{doctor.text}</p>
          <p className="doctor-price">{doctor.price}</p>
          <button className="consult-button">Consult Now</button>
        </div>
      </div>
    ));
  };

  return (
    <div className="container2">
      <div className="header">
        <div className="header-content">
          <p className="greeting">Hi {userName}</p>
          <p className="sub-greeting">Let's Help You To Check Eye's</p>
        </div>
        <img
          src={profileImg}
          alt="Profile"
          className="profile-icon"
          onClick={() => navigate('userScreen', { state: { userEmail, userName } })}
        />
      </div>
      <div className="carousel">
        {carouselData.map((item, index) => (
          <div key={index} className="carousel-item">
            <img src={item.image} alt="carousel" className="carousel-image" />
          </div>
        ))}
      </div>
      <div className="row">
        <div className="card" onClick={() => navigate('ProductInfoScreen2')}>
          <img src={imgSunglasses} alt="Sunglasses" className="image-background" />
          <div className="overlay">
            <p className="overlay-text">Sunglasses</p>
          </div>
        </div>
        <div className="card" onClick={() => navigate('Productpage')}>
          <img src={imgEyeglass} alt="Eyeglasses" className="image-background" />
          <div className="overlay">
            <p className="overlay-text">Eyeglasses</p>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="card" onClick={() => navigate('CameraScreen')}>
          <img src={imgEyeScan} alt="Eye Checkup" className="image-background" />
          <div className="overlay">
            <p className="overlay-text">Eye Checkup</p>
          </div>
        </div>
        <div className="card">
          <img src={imgDoctorConsult} alt="Doctor's Consult" className="image-background" />
          <div className="overlay">
            <p className="overlay-text">Doctor's Consult</p>
          </div>
        </div>
      </div>
      <p className="trending-header">Our Trending Doctor's</p>
      {renderTrendingDoctors()}
    </div>
  );
};

export default HomeScreen;
