import React from "react";
import Logo from '../Assets/Logo.png';
import "../Styles/welcome.css";
import { useNavigate } from "react-router-dom";

const Welcome = () => {
  const navigate = useNavigate();

  return (
    <div>
      <div className="container">
        <img src={Logo} alt="No_LOGO" className="logo" />
        <h1>CIT'S</h1>
      </div>
      <div className="button-container">
        <p className="pareagraph">
          A team of six dedicated individuals is making waves in the world of technology and innovation.
        </p>
        <button className="button" onClick={() => navigate('/User')}>Get Started</button>
      </div>
    </div>
  );
};

export default Welcome;
