import React, { useState, useEffect } from "react";
import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
} from 'firebase/auth';
import {
  getFirestore,
  doc,
  setDoc,
  getDoc,
  collection,
  query,
  where,
  getDocs,
} from "firebase/firestore";
import app from './firebaseConfig';
import { useNavigate } from "react-router-dom";
import '../Styles/User.css';
import Logo from '../Assets/Logo.png';

const User = () => {
  const auth = getAuth(app);
  const db = getFirestore();
  const navigate = useNavigate();
  const [isLoginForm, setIsLoginForm] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [name, setName] = useState("");
  const [isForgotPassword, setIsForgotPassword] = useState(false);

  useEffect(() => {
    checkUser();
  }, []);

  const checkUser = async () => {
    try {
      const user = localStorage.getItem('user');
      if (user) {
        const { userID } = JSON.parse(user);
        const userDoc = await getDoc(doc(db, "users", userID));
        if (userDoc.exists()) {
          const userData = userDoc.data();
          if (userData && 'appPin' in userData && 'chatPin' in userData) {
            if (userData.appPin && userData.chatPin) {
              navigate("/enter-pin");
            } else {
              navigate("/set-pin");
            }
          } else {
            navigate("/set-pin");
          }
        }
      }
    } catch (error) {
      alert("Error in Checking User");
    }
  };

  const toggleForm = () => {
    setIsLoginForm(!isLoginForm);
  };

  const handleAuthentication = async () => {
    if (!email || !password) {
      alert("Please enter email and password.");
      return;
    }

    if (!isLoginForm && !name) {
      alert("Please enter your name.");
      return;
    }

    try {
      if (isLoginForm) {
        const userCredential = await signInWithEmailAndPassword(auth, email, password);
        const user = userCredential.user;
        const userID = user.uid;
        alert("Logged in successfully!");
        localStorage.setItem('user', JSON.stringify({ userID, email }));
        checkUser();
      } else {
        const usersRef = collection(db, "users");
        const q = query(usersRef, where("email", "==", email));
        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
          alert("User already registered. Please login directly.");
          return;
        }
        const userCredential = await createUserWithEmailAndPassword(auth, email, password);
        const userID = userCredential.user.uid;
        await setDoc(doc(db, "users", userID), {
          userID: userID,
          name: name,
          email: email,
          pinSet: false
        });
        localStorage.setItem('user', JSON.stringify({ userID, name, email }));
        checkUser();
        alert("Registered successfully!");
      }
    } catch (error) {
      if (error.code === 'auth/user-not-found') {
        alert("User not found. Please register first.");
      } else if (error.code === 'auth/wrong-password') {
        alert("Incorrect password. Please try again.");
      } else {
        alert("Error in Authentication");
      }
    }
  };

  const handlePasswordReset = async () => {
    if (!email) {
      alert("Please enter your email address.");
      return;
    }

    try {
      await sendPasswordResetEmail(auth, email);
      setIsForgotPassword(false);
      alert("Password reset email sent. Please check your email.");
    } catch (error) {
      alert("Error in sending password reset email.");
    }
  };
  
  return (
    <div className="containerUser">
      <div className="overlay1">
        <img src={Logo} alt="Logo" className="logoUser" />
        <h1 className="heading">CIT'S</h1>
        <div className="formInput">
          {isForgotPassword ? (
            <>
              <h2>Reset Password</h2>
              <input
                className="textInput"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <button className="enterButton" onClick={handlePasswordReset}>
                Reset Password
              </button>
              <button className="buttonUser" onClick={() => setIsForgotPassword(false)}>
                Back to {isLoginForm ? "Login" : "Sign Up"}
              </button>
            </>
          ) : (
            <>
              <h2>{isLoginForm ? "Login" : "Sign Up"}</h2>
              {!isLoginForm && (
                <input
                  className="textInput"
                  placeholder="Name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              )}
              <input
                className="textInput"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <input
                type="password"
                className="textInput"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <button className="enterButton" onClick={handleAuthentication}>
                {isLoginForm ? "Login" : "Sign Up"}
              </button>
              <button className="buttonUser"  onClick={toggleForm}>
                {isLoginForm
                  ? "Don't have an account? Sign up"
                  : "Already have an account? Login"}
              </button>
              {isLoginForm && (
                <button className="buttonUser"  onClick={() => setIsForgotPassword(true)}>
                  Forgot Password?
                </button>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default User;
