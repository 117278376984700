import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Welcome from './Components/welcome';
import User from './Components/Users';
import ChatHome from './Chats/Chat-Home';
import Chats from './Chats/Chats';
import SetPin from './Components/SetPin';
import EnterPin from './Components/EnterPin';
import UserSearchScreen from './Chats/userSearch';
import SenderProfile from './Chats/Sender';
import ReciverProfile from './Chats/ReceiverProfile';
import AppScreen from './AppScreen/appScreen'
const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Welcome />} />
        <Route path="/user" element={<User/>} />
        <Route path="/enter-pin" element={<EnterPin />} />
        <Route path="/Set-pin" element={<SetPin />} />
        <Route path="/chat-home" element={<ChatHome />} />
        <Route path="/user-search" element={<UserSearchScreen />} />
        <Route path="/SenderProfile" element={<SenderProfile />} />
        <Route path="/ReciverProfile" element={<ReciverProfile />} />
        <Route path="/app-home" element={<AppScreen />} />
        <Route path="/chat" element={<Chats />} />
      </Routes>
    </Router>
  );
};

export default App;
